// Import styles
import '@/styles/index.scss';
import 'lazysizes';

import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';

import VanillaTilt from 'vanilla-tilt';
import SelectBox from '@/app/components/SelectBox';
import SortTable from '@/app/components/SortTable';
import { FlyBy } from '@/app/components/FlyBy';

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const CSK = {
  init() {
    VanillaTilt.init();
    this.handleScroll();
    this.initLazyLoad();
    this.initAOS();
    this.initFlickity();
    this.initCar();
    this.initInfiniteScroll();
    this.initFilter();
    this.initViewFilter();

    const flyByContainer = document.querySelector('.FlyBy');
    if (flyByContainer) {
      new FlyBy(flyByContainer, {
        speed: 0.4
      });
    }

    [...document.querySelectorAll('.introduction')].forEach(el => {
      el.addEventListener('click', evt => { // Added quotes around 'click'
        el.classList.add('fadeout');
      }, false);
    });

    [...document.querySelectorAll('.meny__toggle')].forEach(el => {
      el.addEventListener('click', evt => {
        document.querySelector(".navigation__list").classList.toggle("show");
        document.querySelector(".meny__toggle svg").classList.toggle("rotate");
        document.querySelector(".navigation__logo span").classList.toggle("show");
        document.querySelector(".navigation").classList.toggle("background");
      }, false);
    });

    [...document.querySelectorAll('.toggle-modal')].forEach(el => {
      el.addEventListener('click', evt => {
        document.querySelector(".modal").classList.toggle("display-modal");
        document.querySelector("body").classList.toggle("overflow-hidden");
      }, false);
    });
  },

  initLazyLoad() {
    new LazyLoad({
      elements_selector: ".lazy"
    });
  },

  initAOS() {
    AOS.init({
      once: true,
      offset: -200
    });
  },

  initInfiniteScroll() {
    // Uncomment if using infinite scrolling
    // [...document.querySelectorAll('.table__container')].forEach(el => {
    //   new InfiniteScroll(el, {
    //     path: '.pagination__next',
    //     append: '.table__container-item',
    //     history: false
    //   });
    // });
  },

  initFlickity() {
    [...document.querySelectorAll('.gallery')].forEach(el => {
      const test = new Flickity(el, {
        cellAlign: 'left',
        contain: true,
        pageDots: false,
        prevNextButtons: false,
      });
    });
  },

  initCar() {
    [...document.querySelectorAll('.carousel')].forEach(el => {
      const test = new Flickity(el, {
        fade: true,
        pageDots: false,
        arrowShape: 'M45.9533 24.0796L41.5339 19.6602L11.1992 49.9949L41.5339 80.3296L45.9533 75.9102L23.1669 53.1237H84.3763V46.8737H23.1592L45.9533 24.0796'
      });
    });
  },

  initFilter() {
    [...document.querySelectorAll('select')].forEach(el => {
      const box = new SelectBox(el);
      box.listen('change', evt => {
        document.querySelector(".filter__form-button").classList.toggle('active', evt.detail);
      });
    });

    [...document.querySelectorAll('table[data-sortable]')].forEach(el => {
      new SortTable(el);
    });
  },

  initViewFilter() {
    [...document.querySelectorAll('.ViewToggle')].forEach(el => {
      const target = document.querySelector(el.dataset.target);

      if (!target) {
        return;
      }

      console.log(target);

      el.addEventListener('change', evt => {
        const value = el.elements.viewMode.value;
        setCookie('viewMode', value, 60);

        target.dataset.viewMode = value;
      }, false);
    });
  },

  handleScroll(evt) {
    const logo = document.querySelector('#Logo');
    const navBar = document.querySelector('.navigation__list');
    let prevScrollTop = 0;

    window.addEventListener('scroll', evt => {
      const y = window.pageYOffset;

      // Handle Logo bg color animations
      if (logo) {
        if (y >= 20) {
          logo.classList.add('fadeOut');
          document.body.classList.remove('beige');
        } else {
          logo.classList.remove('fadeOut');
          document.body.classList.add('beige');
        }
      }

      if (navBar) {
        const navOffsetHeight = navBar.offsetHeight;

        if (prevScrollTop < y && y > navOffsetHeight * 2) {
          navBar.classList.add("hidden");
        } else if (prevScrollTop > y && !(y <= navOffsetHeight)) {
          navBar.classList.remove("hidden");
        }

        prevScrollTop = y;
      }

    }, false);
  },

  initOnLoad() {
    console.log('Made by ANTI');
    console.log('https://anti.as');
  }
};

document.addEventListener('DOMContentLoaded', () => CSK.init());
window.addEventListener('load', () => CSK.initOnLoad());